/** @jsxImportSource @emotion/react */

import EditableContent from "components/molecules/editable-content";
import useExtractionOperations from "hook/useExtractionOperations";
import { useState } from "react";
import tw from "twin.macro";
import { useSelection } from "../../document-display/SelectionContext";
import InsertHereLine from "components/atoms/insert-here-line";
import { useDocViewNotification } from "components/copilot/extract-v2/context/doc-view-notification-context/docViewNotificationContext";

type Props = {
  isReadOnly: boolean;
  title: string;
  extractionId?: string;
  volumeId?: string;
  parentId?: string;
  sectionId?: string;
};

const RequirementsListHeader = ({ isReadOnly, title, extractionId, parentId, sectionId, volumeId }: Props) => {
  const { setSectionName, setDraftName, bulkAssignExtractionRequirements } = useExtractionOperations();
  const [textCollapsed, setTextCollapsed] = useState(true);
  const { selectedBlocks, clearSelection } = useSelection();
  const { showRequirementSuccessToast } = useDocViewNotification();

  const isSubsectionHeader = !!parentId;
  const isVolumeHeader = !sectionId;

  const placeholder = isVolumeHeader
    ? "Add volume title"
    : isSubsectionHeader
    ? "Add subsection title"
    : "Add section title";

  return (
    <div
      title={title}
      className={`bg-gray-100 border-b border-r border-gray-light z-[2] text-gray-600 sticky top-[41px] hover:z-[3] 
    ${!isVolumeHeader && sectionId ? `section-header-${sectionId}` : ""}`}
      css={[isVolumeHeader && tw`bg-gray-300 text-gray-800 top-0 z-[3]`]}
    >
      <div
        className="peer group/sectionHeader flex flex-row gap-x-1 items-center p-1 px-2 relative"
        css={[
          !!selectedBlocks?.length && !isVolumeHeader && !isReadOnly && tw`cursor-pointer hover:bg-blue-50`,
          isReadOnly && tw`pointer-events-none`,
        ]}
        onClick={() => {
          if (isReadOnly) return;
          if (!!selectedBlocks?.length && !isVolumeHeader) {
            if (!extractionId) return;
            const selectedReqIds = selectedBlocks.map((block) => block.requirement.requirement.id);

            bulkAssignExtractionRequirements(extractionId, selectedReqIds, sectionId, 0);
            clearSelection?.();
          }
        }}
      >
        <EditableContent
          submitOnEnter
          content={title || ""}
          disabled={isReadOnly || (!!selectedBlocks?.length && !isVolumeHeader)}
          onMouseDown={() => {
            setTextCollapsed(false);
          }}
          onContentUpdate={(val) => {
            if (!volumeId) return;

            if (extractionId) {
              if (!isVolumeHeader) setSectionName(extractionId, volumeId, sectionId, val);
              if (isVolumeHeader) setDraftName(extractionId, volumeId, val);
            }
            setTextCollapsed(true);
          }}
          heightProps={[
            textCollapsed && !isVolumeHeader && tw`line-clamp-3 max-h-[60px] overflow-hidden`,
            textCollapsed && isVolumeHeader && tw`line-clamp-1 max-h-[20px] overflow-hidden`,
          ]}
          css={[tw`text-sm font-medium p-1.5 flex-1 hover:bg-gray-200`, isVolumeHeader && tw`hover:bg-zinc-400/30`]}
          textareaProps={{
            placeholder,
          }}
        />
      </div>
      {!isVolumeHeader && !isReadOnly && (
        <InsertHereLine
          onHoverOnly
          onClick={() => {
            if (!extractionId) return;
            const selectedReqIds = selectedBlocks?.map((block) => block.requirement.requirement.id) || [];

            bulkAssignExtractionRequirements(extractionId, selectedReqIds, sectionId, 0);
            showRequirementSuccessToast("assigned to Proposal Outline", selectedReqIds.length);
          }}
        />
      )}
    </div>
  );
};

export default RequirementsListHeader;
