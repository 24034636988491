/** @jsxImportSource @emotion/react */

import tw from "twin.macro";
import { TemplateSection } from "types/TemplateSection";

type Props = {
  section: TemplateSection;
};

const TemplateSectionRow = ({ section }: Props) => {
  const isSubsection = !("subsections" in section);

  return (
    <div className="relative text-[13px] py-2 w-full min-w-0" css={[isSubsection && tw`ml-4`]}>
      <div className="absolute -left-3 inset-y-0 w-0.5 bg-gray-300" />
      <div className="flex flex-row items-center">
        <span className="whitespace-nowrap font-medium" css={[!section.title.trim() && tw`text-gray-400`]}>
          {section.title || "Section title..."}
        </span>
      </div>
    </div>
  );
};

export default TemplateSectionRow;
