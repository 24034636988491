import { useStorage } from "YJSProvider/createYJSContext";

const DEFAULT_VERSION = 0;

export function useCurrentVersion(extractionId: string): number {
  const version = useStorage(
    (storage) => storage.extractions?.find((extraction) => extraction.id === extractionId)?.framework?.volumesVersion
  );
  return version || DEFAULT_VERSION;
}

export function useCurrentFrameworkVersion(): number {
  return useStorage((storage) => storage.framework?.volumesVersion) || DEFAULT_VERSION;
}
