import { redo, undo } from "prosemirror-history";
import type { Editor } from "@tiptap/react";
import { useCallback } from "react";

import { LEVEL_LABELS, MIN_LEVEL } from "../constants";
import { canIndent, canOutdent, expandSelectionToFullNodes, indent, outdent } from "../plugins/utils";
import { ToolbarButton } from "./ToolbarButton";
import { ToolbarDivider } from "./ToolbarDivider";
import { getShortcutKey } from "./utils";
import { createNode } from "../hooks/utils";
import type { Level } from "@tiptap/extension-heading";
import { useLocalFontSize } from "../hooks/useLocalFontSize";
import { SmallIntegerInput } from "./SmallIntegerInput";
import { ToolbarGroup } from "./ToolbarGroup";
import type { EditorActive } from "../types";

type Props = {
  active: EditorActive;
  canLockEdits: boolean;
  editor: Editor;
  editorRef: React.RefObject<HTMLDivElement>;
  isLocked: boolean;
  isReadOnly: boolean;
  maxLevel: Level;
  setIsLocked: (newIsLocked: boolean) => unknown;
};

function Toolbar({ active, editor, maxLevel, editorRef, setIsLocked, isLocked, isReadOnly, canLockEdits }: Props) {
  const { canIncrease, canDecrease, increaseFontSize, decreaseFontSize, fontSize, setFontSize } =
    useLocalFontSize(editorRef);
  const handleUndo = useCallback(() => {
    undo(editor.view.state, editor.view.dispatch);
  }, [editor]);

  const handleRedo = useCallback(() => {
    redo(editor.view.state, editor.view.dispatch);
  }, [editor]);

  const handleRemove = useCallback(() => {
    editor
      .chain()
      .focus()
      .command(({ state, dispatch }) => {
        const [modified, tr] = expandSelectionToFullNodes(state);

        if (modified) {
          dispatch?.(
            tr.deleteSelection().setMeta("addToHistory", true) // Marks the combined transaction as a single history entry
          );
          return true;
        }
        return false; // Let default cut behavior occur if no changes
      })
      .run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  const ctrl = getShortcutKey();
  const currentLevel = (active.nodes[0]?.node.attrs.level ?? MIN_LEVEL) as Level;

  return (
    <div className="flex flex-wrap pt-2 pb-2 pl-1.5 pr-1.5 bg-white border-b border-gray-light">
      <ToolbarGroup>
        <ToolbarButton
          icon="Plus"
          title={`Add ${LEVEL_LABELS[currentLevel] || "Volume/Section"}`}
          disabled={!editor}
          onClick={() => {
            editor
              .chain()
              .focus()
              .insertContentAt(editor.state.selection.$anchor.end(), createNode(currentLevel))
              .run();
          }}
        />

        <ToolbarDivider />
      </ToolbarGroup>

      <ToolbarGroup>
        <ToolbarButton
          icon="indent-decrease"
          title="Outdent (Shift + Tab)"
          disabled={!editor || !canOutdent(editor)}
          onClick={() => outdent(editor)}
        />
        <ToolbarButton
          icon="indent-increase"
          title="Indent (Tab)"
          disabled={!editor || !canIndent(editor, maxLevel)}
          onClick={() => indent(editor, maxLevel)}
        />

        <ToolbarDivider />
      </ToolbarGroup>

      <ToolbarGroup>
        <ToolbarButton
          icon="Undo"
          title={`Undo (${ctrl} + Z)`}
          disabled={!editor || !editor.can().undo()}
          onClick={handleUndo}
        />
        <ToolbarButton
          icon="Undo"
          flipHorizontal
          title={`Redo (Shift + ${ctrl} + Z)`}
          disabled={!editor || !editor.can().redo()}
          onClick={handleRedo}
        />
        <ToolbarDivider />
      </ToolbarGroup>

      <ToolbarGroup noGap>
        <ToolbarButton
          icon="minus"
          sm
          title={`Decrease font size`}
          disabled={!editor || !canDecrease()}
          onClick={decreaseFontSize}
        />
        <SmallIntegerInput value={fontSize} onChange={setFontSize} />
        <ToolbarButton
          icon="plus"
          sm
          title={`Increase font size`}
          disabled={!editor || !canIncrease()}
          onClick={increaseFontSize}
        />
        <ToolbarDivider />
      </ToolbarGroup>

      <ToolbarGroup>
        <ToolbarButton icon="Trash" title="Remove" disabled={!editor} onClick={handleRemove} />
        {canLockEdits ? (
          <ToolbarButton
            icon={isLocked ? "lock-keyhole-open" : "lock-keyhole"}
            title={isLocked ? "Unlock Document" : "Lock Document"}
            disabled={!editor || isReadOnly}
            onClick={() => setIsLocked(!isLocked)}
          />
        ) : null}
      </ToolbarGroup>
    </div>
  );
}

export default Toolbar;
