import { Node, Slice } from "@tiptap/pm/model";
import type { Level } from "@tiptap/extension-heading";
import { EditorView } from "@tiptap/pm/view";
import type React from "react";
import { Editor, isTextSelection } from "@tiptap/core";
import { Attrs } from "@tiptap/pm/model";
import { TextSelection, EditorState, Transaction } from "prosemirror-state";

import { OutlineContent } from "../types";
import { MIN_LEVEL } from "../constants";
import { createNode } from "../hooks/utils";

/**
 * Adds necessary attributes (`xid` and `level`) to a node's attributes object
 * if they are missing. Generates a new unique `xid` and sets a default `level` of 1
 * when absent.
 *
 * @param {Node["attrs"]} attrs - The attributes of a node.
 * @returns {[Node["attrs"], boolean]} - A tuple with the updated attributes and
 * a boolean indicating if any attribute was added or updated.
 */
export function addAttributes(attrs: Node["attrs"]): [Node["attrs"], boolean] {
  let needsUpdate = false;
  // Check and assign 'xid' if missing
  if (!attrs?.xid) {
    needsUpdate = true;
  }

  // Check and assign 'level' if missing
  if (!attrs?.level) {
    needsUpdate = true;
  }

  if (needsUpdate) {
    const newFallbackAttrs = createNode(attrs.level || MIN_LEVEL).attrs;
    return [
      {
        ...newFallbackAttrs,
        ...attrs,
      },
      true,
    ];
  }

  return [attrs, false];
}

function getIndentMap(lines: string[], referenceLevel: number, maxLevel: Level): { [key: number]: Level } {
  // Calculate the smallest indentation increment among all lines
  const indentations = Array.from(
    new Set(lines.map((line) => line.match(/^(\s*)/)?.[0].length || 0).sort((a, b) => a - b))
  );

  indentations.length = Math.min(maxLevel, indentations.length);

  return Object.fromEntries(
    indentations.map((indent, index) => [indent, Math.min(maxLevel, index + referenceLevel) as Level])
  );
}

const bulletRegExp = /^\s*[-–—+<>·•∙◦⦾⦿‣⁌⁍*]?\s*/;

export function parseTextToOutline(text: string, referenceLevel: Level = MIN_LEVEL, maxLevel: Level): OutlineContent[] {
  const lines = text.split("\n").filter((line) => !!line.trim().replace(bulletRegExp, "").length); // Remove empty lines
  const indentToLevelMap = getIndentMap(lines, referenceLevel, maxLevel);

  return lines.map((line) => {
    const contentIndentation = line.match(/^\s*/)?.[0].length || 0;
    const level = indentToLevelMap[contentIndentation] || maxLevel;
    const text = line.replace(bulletRegExp, "").trim();

    return {
      attrs: createNode(level).attrs,
      text,
    };
  });
}

// Attempt to parse clipboardText as JSON to check for nodes with `xid`
function doesSliceHaveXid(slice?: Slice) {
  let hasXid = false;

  if (slice) {
    slice.content.descendants((node) => {
      if (node.attrs.xid) {
        hasXid = true;
      }
    });
  }
  return hasXid;
}

export function applyPaste(
  view: EditorView,
  maxLevel: Level,
  text?: string,
  event?: ClipboardEvent | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  slice?: Slice
): boolean {
  if (!text) {
    return false;
  }

  const currentLevel = view.state.selection.$anchor.node().attrs.level || MIN_LEVEL;

  if (text) {
    // Check if the pasted content has any node with attrs.xid
    const hasXid = doesSliceHaveXid(slice);

    if (!hasXid) {
      event?.preventDefault();
      // Parse the external text and insert as nodes and
      const nodes = parseTextToOutline(text, currentLevel, maxLevel);
      const { tr } = view.state;

      nodes.forEach((parsedNode) => {
        const nodeType = view.state.schema.nodes[`heading`]; // Assuming all are heading nodes
        if (nodeType) {
          const node = nodeType.createAndFill(parsedNode.attrs, view.state.schema.text(parsedNode.text));
          if (node) {
            tr.replaceSelectionWith(node, false);
          }
        }
      });

      view.dispatch(tr.scrollIntoView());
      return true;
    }
  }

  // If content has xid, let the default handler work
  return false;
}

function safeGetLevelAt(doc: Node, index: number) {
  try {
    return doc.child(index - 1)?.attrs?.level || MIN_LEVEL;
  } catch {
    return MIN_LEVEL;
  }
}

export function canIndent(editor: Editor, maxLevel: Level): boolean {
  const { $anchor } = editor.state.selection;
  const { level = MIN_LEVEL } = $anchor.node().attrs || {};
  const currentIndex = $anchor.index($anchor.depth - 1);

  if (currentIndex === 0) {
    return false;
  }

  const previousLevel = safeGetLevelAt(editor.state.doc, currentIndex);

  // Calculate the new level
  const boundMaxLevel = Math.min(maxLevel, previousLevel + 1) as Level;
  const newLevel = Math.min(boundMaxLevel, level + 1) as Level;

  return newLevel > level;
}

export function indent(editor: Editor, maxLevel: Level) {
  const { state } = editor;
  const { selection, doc, tr } = state;
  const { from, to } = selection;

  // Iterate over all selected nodes
  doc.nodesBetween(from, to, (node, pos) => {
    if (node.type.name === "heading") {
      const { level = MIN_LEVEL } = node.attrs || {};

      // Find the previous node's level (hierarchical constraint)
      const previousNode = doc.childBefore(pos);
      const previousLevel = previousNode?.node?.attrs?.level || MIN_LEVEL;

      // Calculate the new level for the current node
      const newLevel = Math.min(maxLevel, level + 1, previousLevel + 1) as Level;

      // Apply the level change
      if (newLevel !== level) {
        tr.setNodeMarkup(pos, undefined, {
          ...node.attrs,
          level: newLevel,
        });
      }
    }
  });

  // Dispatch the transaction if changes were made
  if (tr.docChanged) {
    editor.view.dispatch(tr);
  }
}

export function canOutdent(editor: Editor): boolean {
  const { level = MIN_LEVEL } = editor.state.selection.$anchor.node().attrs || {};
  const nextHeading = Math.max(MIN_LEVEL, level - 1) as Level;

  return nextHeading < level;
}

export function outdent(editor: Editor) {
  const { state } = editor;
  const { selection, doc, tr } = state;
  const { from, to } = selection;

  // Iterate over all selected nodes
  doc.nodesBetween(from, to, (node, pos) => {
    if (node.type.name === "heading") {
      const { level = MIN_LEVEL } = node.attrs || {};

      // Calculate the new level for the current node
      const newLevel = Math.max(MIN_LEVEL, level - 1) as Level;

      // Apply the level change
      if (newLevel !== level) {
        tr.setNodeMarkup(pos, undefined, {
          ...node.attrs,
          level: newLevel,
        });
      }
    }
  });

  // Dispatch the transaction if changes were made
  if (tr.docChanged) {
    editor.view.dispatch(tr);
  }
}

export function setNodeAttrDefaults(node: { attrs: Attrs }) {
  if (!node.attrs) {
    console.warn("Current node lacks attributes. Initializing with default attributes.");
    node.attrs = createNode(MIN_LEVEL).attrs;
  }
}

/**
 * Expands the selection to full nodes if partially selected.
 * @param state - The current editor state.
 * @returns [modified, transaction] - A boolean indicating modification and a new transaction with updated selection.
 */
export function expandSelectionToFullNodes(state: EditorState): [boolean, Transaction] {
  const { selection } = state;

  if (isTextSelection(selection)) {
    const startPos = selection.$from.start();
    const endPos = selection.$to.end();

    let newStart = startPos;
    let newEnd = endPos;

    const tr = state.tr;
    let modified = false;

    state.doc.nodesBetween(startPos, endPos, (node, pos) => {
      if (node.type.name === "heading") {
        // Extend selection to the start and end of each "heading" node
        if (pos < startPos) newStart = Math.min(newStart, pos);
        if (pos + node.nodeSize > endPos) newEnd = Math.max(newEnd, pos + node.nodeSize);
        modified = true;
      }
    });

    if (modified && (newStart !== startPos || newEnd !== endPos)) {
      tr.setSelection(TextSelection.create(state.doc, newStart, newEnd)).setMeta("addToHistory", false);
      return [true, tr];
    }
  }

  // If no modifications were needed, return the original transaction
  return [false, state.tr];
}
