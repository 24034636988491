import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { DRIVE_ROUTES } from "Routes/drive";
import { useNotification } from "context/notificationContext";
import { useHandleApiError } from "hook/useHandleApiError";
import { queryClient } from "api/queryClient";
import { Subdirectory, WorkspaceMedia } from "types/FileStorage";
import { useAppDispatch } from "store/storeTypes";
import { fetchMediaStorage } from "store/reducers/driveReducerSlice";
import { pluralizeWord } from "utils/string";

export const mediaKeys = {
  all: ["media"],
  directory: (id?: string | null) => [...mediaKeys.all, "directory", id || "root"],
};

// API functions
const api = {
  getDirectory: async (directoryId?: string) => {
    // if directoryId is not provided, get the root directory
    const url = directoryId ? DRIVE_ROUTES.media.directory.get(directoryId) : DRIVE_ROUTES.media.directory.getRoot;
    const { data } = await axios.get(url);
    return data;
  },

  createFolder: async ({ name, parent_directory_id }: { name: string; parent_directory_id?: string }) => {
    const { data } = await axios.post(DRIVE_ROUTES.media.directory.create, { name, parent_directory_id });
    return data;
  },

  updateFolder: async ({ id, name }: { id: string; name: string }) => {
    const { data } = await axios.put(DRIVE_ROUTES.media.directory.update(id), { name });
    return data;
  },

  deleteFolder: async (id: string) => {
    await axios.delete(DRIVE_ROUTES.media.directory.delete(id));
    return id;
  },

  moveFolder: async (id: string, data: { parent_directory_id?: string } = {}) => {
    await axios.post(DRIVE_ROUTES.media.directory.move(id), data);
    return { id, ...data };
  },

  createFile: async ({
    file,
    parent_directory_id,
    user_tags = [],
    visible_in_content_library = true,
  }: {
    file: File;
    parent_directory_id?: string;
    user_tags?: string[];
    visible_in_content_library?: boolean;
  }) => {
    const formData = new FormData();
    formData.append("file", file);
    if (parent_directory_id) formData.append("parent_directory_id", parent_directory_id);
    user_tags.forEach((tag) => formData.append("user_tags", tag));
    formData.append("visible_in_content_library", String(visible_in_content_library));

    const { data } = await axios.post(DRIVE_ROUTES.media.file.create, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return data;
  },

  updateFile: async (id: string, data: Partial<WorkspaceMedia>) => {
    const { data: response } = await axios.put(DRIVE_ROUTES.media.file.update(id), data);
    return response;
  },

  deleteFiles: async (fileIds: string[]) => {
    await axios.post(DRIVE_ROUTES.media.file.deleteMultiple, { file_ids: fileIds });
    return fileIds;
  },

  moveFiles: async ({
    fileIds,
    parent_directory_id,
    source_directory_id,
    target_directory_id,
  }: {
    fileIds: string[];
    parent_directory_id?: string;
    source_directory_id?: string | null;
    target_directory_id?: string | null;
  }) => {
    await axios.post(DRIVE_ROUTES.media.file.moveMultiple, { file_ids: fileIds, parent_directory_id });
    return { fileIds, parent_directory_id, source_directory_id, target_directory_id };
  },
};

export const useMediaDirectory = (directoryId?: string) => {
  return useQuery({
    queryKey: mediaKeys.directory(directoryId),
    queryFn: () => api.getDirectory(directoryId),
  });
};

export const useMediaOperations = (directoryId?: string | null) => {
  const { setToast } = useNotification();
  const { setError } = useHandleApiError();
  const dispatch = useAppDispatch();

  const handleOptimisticUpdate = async (queryKey: string[], updateFn: any) => {
    await queryClient.cancelQueries({ queryKey: mediaKeys.all });
    const previousData = queryClient.getQueryData(queryKey);
    queryClient.setQueryData(queryKey, updateFn);
    return { previousData };
  };

  const createFolderMutation = useMutation({
    mutationFn: api.createFolder,
    onMutate: async ({ name, parent_directory_id }) => {
      await queryClient.cancelQueries({ queryKey: mediaKeys.all });
      const queryKey = mediaKeys.directory(parent_directory_id);
      const previousData = queryClient.getQueryData(queryKey);

      // Create temporary folder
      const tempFolder: Subdirectory = {
        id: `temp-${Date.now()}`,
        name,
        parent_directory: parent_directory_id || null,
        workspace: "",
        created_by: "",
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        all_nested_files: [],
      };

      queryClient.setQueryData(queryKey, (old: any) => ({
        ...old,
        subdirectories: [...(old?.subdirectories || []), tempFolder],
      }));

      return { previousData, queryKey };
    },
    onError: (_, __, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(context.queryKey, context.previousData);
      }
      setError(
        "Failed to create folder. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
      );
    },
    onSuccess: () => {
      dispatch(fetchMediaStorage());
      queryClient.invalidateQueries({ queryKey: mediaKeys.all });
      setToast.success({ msg: "Folder created" });
    },
  });

  const updateFolderMutation = useMutation({
    mutationFn: api.updateFolder,
    onSuccess: () => {
      dispatch(fetchMediaStorage());
      queryClient.invalidateQueries({ queryKey: mediaKeys.all });
      setToast.success({ msg: "Folder updated" });
    },
    onError: () =>
      setError(
        "Failed to update the folder. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
      ),
  });

  const deleteFolderMutation = useMutation({
    mutationFn: api.deleteFolder,
    onMutate: async (id) => {
      const queryKey = mediaKeys.directory(directoryId);
      return handleOptimisticUpdate(queryKey, (old: any) => ({
        ...old,
        subdirectories: old.subdirectories.filter((folder: Subdirectory) => folder.id !== id),
      }));
    },
    onError: (_, __, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(mediaKeys.directory(directoryId), context.previousData);
      }
      setError(
        "Failed to delete folder. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
      );
    },
    onSuccess: () => {
      dispatch(fetchMediaStorage());
      queryClient.invalidateQueries({ queryKey: mediaKeys.all });
      setToast.success({ msg: "Folder deleted" });
    },
  });

  const moveFolderMutation = useMutation({
    mutationFn: ({ id, parent_directory_id }: { id: string; parent_directory_id?: string }) =>
      api.moveFolder(id, { parent_directory_id }),
    onSuccess: () => {
      dispatch(fetchMediaStorage());
      queryClient.invalidateQueries({ queryKey: mediaKeys.all });
      setToast.success({ msg: "Folder moved" });
    },
    onError: () =>
      setError(
        "Failed to move the folder. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
      ),
  });

  const createFileMutation = useMutation({
    mutationFn: api.createFile,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: mediaKeys.all });
      setToast.success({ msg: "Graphic uploaded" });
    },
    onError: () =>
      setError(
        "Failed to upload graphic. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
      ),
  });

  const updateFileMutation = useMutation({
    mutationFn: async ({ id, ...data }: { id: string } & Partial<WorkspaceMedia>) => {
      return api.updateFile(id, data);
    },
    onMutate: async ({ id, ...data }) => {
      await queryClient.cancelQueries({ queryKey: mediaKeys.all });
      const queryKey = mediaKeys.directory(directoryId);
      const previousData = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (old: any) => ({
        ...old,
        files: old.files.map((file: WorkspaceMedia) =>
          file.id === id
            ? {
                ...file,
                ...data,
                updated_at: new Date().toISOString(),
              }
            : file
        ),
      }));

      return { previousData, queryKey };
    },
    onError: (_, __, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(context.queryKey, context.previousData);
      }
      setError(
        "Failed to update graphic. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: mediaKeys.directory(directoryId) });
      setToast.success({ msg: "Graphic updated" });
    },
  });

  const deleteFilesMutation = useMutation({
    mutationFn: api.deleteFiles,
    onMutate: async (fileIds) => {
      const queryKey = mediaKeys.directory(directoryId);
      return handleOptimisticUpdate(queryKey, (old: any) => ({
        ...old,
        files: old.files.filter((f: WorkspaceMedia) => !fileIds.includes(f.id)),
      }));
    },
    onError: (_, __, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(mediaKeys.directory(directoryId), context.previousData);
      }
      setError(
        "Failed to delete graphic(s). Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
      );
    },
    onSuccess: (fileIds) => {
      queryClient.invalidateQueries({ queryKey: mediaKeys.all });
      setToast.success({
        msg: `${fileIds.length} ${pluralizeWord(fileIds.length, "Graphic")} deleted`,
      });
    },
  });

  const moveFilesMutation = useMutation({
    mutationFn: api.moveFiles,
    onMutate: async ({ fileIds, parent_directory_id, source_directory_id, target_directory_id }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: mediaKeys.all });

      // Get the correct query keys for both source and target
      const sourceQueryKey = mediaKeys.directory(source_directory_id);
      const targetQueryKey = mediaKeys.directory(target_directory_id);

      // Store previous states
      const previousSourceData = queryClient.getQueryData(sourceQueryKey) as { files: WorkspaceMedia[] };
      const previousTargetData = queryClient.getQueryData(targetQueryKey) as { files: WorkspaceMedia[] };

      // Optimistically update source directory (remove files)
      if (source_directory_id !== undefined) {
        queryClient.setQueryData(sourceQueryKey, (old: any) => ({
          ...old,
          files: old?.files?.filter((f: WorkspaceMedia) => !fileIds.includes(f.id)) || [],
        }));
      }

      // Optimistically update target directory if we have the data (add files)
      if (target_directory_id !== undefined) {
        const movedFiles = previousSourceData?.files
          ?.filter((f: WorkspaceMedia) => fileIds.includes(f.id))
          .map((f: WorkspaceMedia) => ({
            ...f,
            parent_directory: parent_directory_id || null,
          }));

        if (movedFiles?.length) {
          queryClient.setQueryData(targetQueryKey, (old: any) => ({
            ...old,
            files: [...(old?.files || []), ...movedFiles],
          }));
        }
      }

      return {
        previousSourceData,
        previousTargetData,
        sourceQueryKey,
        targetQueryKey,
      };
    },
    onError: (_, __, context) => {
      // Revert optimistic updates on error
      if (context) {
        queryClient.setQueryData(context.sourceQueryKey, context.previousSourceData);
        queryClient.setQueryData(context.targetQueryKey, context.previousTargetData);
      }
      setError(
        "Failed to move the graphic(s). Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
      );
    },
    onSuccess: ({ fileIds }) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: mediaKeys.all });
      setToast.success({ msg: `${fileIds.length} ${pluralizeWord(fileIds.length, "Graphic")} moved` });
    },
  });

  return {
    createFolder: createFolderMutation.mutate,
    updateFolder: updateFolderMutation.mutate,
    deleteFolder: deleteFolderMutation.mutate,
    moveFolder: moveFolderMutation.mutate,
    createFile: createFileMutation.mutate,
    updateFile: (id: string, data: Partial<WorkspaceMedia>) => updateFileMutation.mutateAsync({ id, ...data }),
    deleteFiles: deleteFilesMutation.mutate,
    moveFiles: moveFilesMutation.mutate,
    isLoading: [
      createFolderMutation,
      updateFolderMutation,
      deleteFolderMutation,
      moveFolderMutation,
      createFileMutation,
      updateFileMutation,
      deleteFilesMutation,
      moveFilesMutation,
    ].some((mutation) => mutation.isPending),
    isCreatingFile: createFileMutation.isPending,
  };
};
