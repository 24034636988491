/** @jsxImportSource @emotion/react */

import Popover from "components/atoms/popover/Popover";
import ContentDrivePopoverContent from "components/molecules/content-drive-popover-content";
import { useCallback, useMemo, useRef, useState } from "react";
import {
  insertAssistantFiles,
  removeAssistantFiles,
  setAssistantPrompt,
  setToggleInternet,
} from "store/reducers/ai-assistant/aiAssistantReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import { BaseFile } from "types/FileStorage";
import { transformToFileMention } from "components/copilot/writing-assistant-drawer/writing-assistant-input/utils";
import VoiceTranslateItem from "components/voice-assist/VoiceTranslateItem";
import Icon from "components/atoms/icons/Icon";
import Tooltip from "components/atoms/tooltip";
import { SOURCE_TO_META, Source } from "./constants";
import { useFlags } from "hook/useFlags";

const AIAssistantInputActions = () => {
  const [sourceOpen, setSourceOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const ref = useRef(null);
  const flags = useFlags();
  const dispatch = useAppDispatch();
  const { selectedFiles, prompt, enableInternet } = useAppSelector((root) => root.aiAssistantState);
  const selectedFileIds = useMemo(() => selectedFiles.map(({ id }) => id), [selectedFiles]);

  const handleSelectedDriveFiles = useCallback(
    (checked: boolean, files: BaseFile[]) => {
      if (checked) {
        dispatch(insertAssistantFiles(transformToFileMention(files)));
      } else dispatch(removeAssistantFiles(files.map(({ id }) => id)));
    },
    [dispatch]
  );

  const trailingSpace = (prompt: string): string => {
    if (prompt === "" || prompt[prompt.length - 1] === " ") {
      return "";
    } else {
      return " ";
    }
  };

  const handleTranslation = (message: string) => {
    const spacing = trailingSpace(prompt);
    dispatch(setAssistantPrompt(prompt + spacing + message));
  };

  return (
    <div ref={ref} className="flex items-center gap-1.5 px-3">
      <div className="px-1">
        <VoiceTranslateItem styles={tw`bg-gray-700 duration-150 hover:bg-black`} onComplete={handleTranslation} />
      </div>
      <div className="flex items-center">
        <Popover
          content={
            <ContentDrivePopoverContent
              handleSelectedFiles={handleSelectedDriveFiles}
              onClear={(files) => dispatch(removeAssistantFiles(files.map(({ id }) => id)))}
              selectedFiles={selectedFileIds}
            />
          }
          open={sourceOpen}
          onOpenChange={(o) => {
            setSourceOpen(o);
          }}
          searchProps={{
            value: query,
            onChange: (e) => setQuery(e.target.value),
          }}
          contentProps={{
            align: "start",
            side: "top",
            css: [tw`backdrop-blur-sm ml-0 bg-[rgba(255,255,255,0.95)]`],
          }}
          portalProps={{ container: ref.current }}
        >
          <div
            className="cursor-pointer flex p-1 pl-2 pr-1 rounded-md text-gray-600 hover:text-black duration-100 hover:bg-gray-200"
            css={[!!selectedFiles.length && tw`bg-gray-200`]}
          >
            <Icon name="Paperclip" className="w-5 h-5" />
            <div className="pr-2 pl-1.5 text-sm"> Content Library </div>
          </div>
        </Popover>
      </div>
      {!flags.disableInternetChat && (
        <Tooltip delayDuration={800} content={SOURCE_TO_META[Source.Internet].tooltip}>
          <button
            className="flex p-1 pl-2 pr-1 rounded-md text-gray-600 hover:text-black duration-100 hover:bg-gray-200"
            key={Source.Internet}
            onClick={() => dispatch(setToggleInternet())}
            css={[enableInternet && tw`bg-gray-200`]}
          >
            <Icon name="Internet" className="w-5 h-5" />
            <div className="pr-2 pl-1.5 text-sm"> Internet </div>
          </button>
        </Tooltip>
      )}
    </div>
  );
};

export default AIAssistantInputActions;
