import { PropsWithChildren, ReactNode, createContext, useCallback, useContext, useMemo, useState } from "react";
import * as Toast from "@radix-ui/react-toast";
import DocumentToast from "./DocumentToast";
import { CircleCheck } from "lucide-react";
import { pluralizeWord } from "utils/string";

interface NotificationContextState {
  setToast: {
    success: (state: Omit<NotificationToastState, "variant">) => void;
    warning: (state: Omit<NotificationToastState, "variant">) => void;
  };
  clearToast: () => void;
  notification?: Partial<NotificationToastState>;
  showRequirementSuccessToast: (action: string, count: number) => void;
}

const DocViewNotificationContext = createContext<NotificationContextState>({} as NotificationContextState);

export enum NotificationType {
  Success = 1,
  Warning,
}

type NotificationToastState = {
  variant: NotificationType;
  title?: ReactNode;
  msg?: ReactNode;
  duration?: number;
};

const DocViewNotificationsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [notification, setNotification] = useState<NotificationContextState["notification"]>();

  const handleAddToast = useCallback((state: NotificationToastState) => {
    setNotification(state);
  }, []);

  const setToast: NotificationContextState["setToast"] = useMemo(
    () => ({
      success: (state) => handleAddToast({ ...state, variant: NotificationType.Success }),
      warning: (state) => handleAddToast({ ...state, variant: NotificationType.Warning }),
    }),
    [handleAddToast]
  );

  const showRequirementSuccessToast = useCallback(
    (action: string, count: number) => {
      setToast.success({
        msg: (
          <div className="flex flex-row gap-2 items-center text-sm text-white">
            <CircleCheck size={14} />
            <span>{`${count} ${pluralizeWord(count, "requirement")} ${action}.`}</span>
          </div>
        ),
      });
    },
    [setToast]
  );

  const clearToast = useCallback(() => setNotification(undefined), []);

  return (
    <DocViewNotificationContext.Provider value={{ setToast, showRequirementSuccessToast, notification, clearToast }}>
      <Toast.Provider swipeDirection="left">
        <DocumentToast />
        {children}
      </Toast.Provider>
    </DocViewNotificationContext.Provider>
  );
};

export const useDocViewNotification = () => useContext(DocViewNotificationContext);

export default DocViewNotificationsProvider;
