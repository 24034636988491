import driveWhite from "Assets/drive-white.svg";
import folderOpenSm from "Assets/folder-open-white.svg";
import lod from "Assets/loader.svg";
import searchIcon from "Assets/search-normal.png";
import { createFile, updateFile } from "api/api";
import { Plus } from "lucide-react";
import CustomModal from "components/CustomModal";
import Document from "components/Drive/Document";
import Folder from "components/Drive/Folder";
import UploadDriveDocModal from "components/Drive/UploadDriveDocModal";
import ExtractingDocumentToast from "components/LoadingToast";
import { useCallback, useEffect, useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { fetchFileStorage, updateAllDocs, updateAllFolders } from "store/reducers/driveReducerSlice";
import { formatBytes } from "utils/helpers";
import { CloseIconBlack } from "utils/icons";
import MoveModal from "../MoveModal";
import { useDirectoryFileSearch } from "./hooks";
import NotFoundItems from "components/NotFoundItems";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { xor } from "lodash";
import Icon from "components/atoms/icons/Icon";
import { useContentLibraryFilterItems } from "components/molecules/content-drive-popover-content/hooks";
import { LEGACY_DOCUMENT_TYPES } from "pages/drive/documents/constants";
import { SelectedDocumentsToolbar } from "./SelectedDocumentsToolbar";
import { useFileOperations } from "./useFileOperations";
import { ContentLibraryPanel } from "../sidebar/ContentLibraryPanel";
import { useFolderOperations } from "./useFolderOperations";
import { useOperationsUtils } from "./useOperationsUtils";
import { Checkbox } from "components/atoms/checkbox";
import { pluralizeWord } from "utils/string";
import { useDragSelect } from "components/Drive/useDragSelect";

const DocumentsContent = () => {
  const dispatch = useDispatch();
  const {
    rootFiles: allDocs,
    rootSubdirectories: allFolders,
    isInitialFetch,
  } = useSelector((state) => state.drive.fileStorage);
  const {
    isSearching,
    searchResults,
    isSearchActive,
    setDocumentTypes,
    setSearchQuery,
    searchQuery,
    documentTypes,
    setUploaded,
    uploaded,
    folderSearchResults,
    throttleSearch,
  } = useDirectoryFileSearch();

  const { handleApiError, handleApiSuccess, handleInvalidFileMsg } = useOperationsUtils();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [currentFolders, setCurrentFolders] = useState([]);
  const [currentDocs, setCurrentDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingToast, setLoadingToast] = useState({ loading: false, files: 1 });
  const [draggingFolderId, setDraggingFolderId] = useState("");
  const [draggingDocIds, setDraggingDocIds] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [selectedFolderHeading, setSelectedFolderHeading] = useState("");
  const [stateHistory, setStateHistory] = useState({
    type: null,
    data: [],
    itemInfo: {},
  });
  const [ctrlKeyPressed, setCtrlKeyPressed] = useState(false);
  const { typeItems, publishDateItemOptions } = useContentLibraryFilterItems({
    publishDatesConfig: { uploaded, onSelect: (val) => setUploaded(val === uploaded ? "" : val) },
    typesConfig: {
      documentTypes,
      onCheck: (val) => {
        if (Array.isArray(val)) {
          setDocumentTypes((prev) => xor(prev, val));
        } else {
          setDocumentTypes((prev) => xor(prev, [val]));
        }
      },
    },
  });
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const containerRef = useRef(null);

  const refreshResults = useCallback(() => {
    if (isSearchActive) {
      throttleSearch(true);
    } else {
      dispatch(fetchFileStorage());
    }
  }, [dispatch, isSearchActive, throttleSearch]);

  const { moveDocument, deleteDocument, moveModalOpen, handleMoveModalOpen, handleCloseMoveModal, onMoveManually } =
    useFileOperations(allDocs, allFolders, refreshResults, setStateHistory, setSelectedDocuments);

  const {
    folderModal,
    createFolder,
    updateFolder,
    deleteFolder: deleteSelectedDirectory,
    moveFolder: moveSelectedDirectory,
    openFolderModal,
    closeFolderModal,
    updateFolderName,
  } = useFolderOperations(allFolders, refreshResults, setStateHistory, {
    isRootLevel: true,
  });

  const handleDocumentSelect = useCallback((id, isSelected) => {
    setSelectedDocuments((prev) => (isSelected ? [...prev, id] : prev.filter((docId) => docId !== id)));
  }, []);

  useDragSelect({
    onSelect: handleDocumentSelect,
    selectedDocuments,
    containerRef,
  });

  const handleMoveSelected = useCallback(() => {
    handleMoveModalOpen(selectedDocuments, "doc");
  }, [selectedDocuments, handleMoveModalOpen]);

  const handleDeleteSelected = () => {
    if (selectedDocuments.length > 0) {
      deleteDocument(selectedDocuments);
    }
  };

  const handleDocumentClick = useCallback((doc) => {
    setSelectedDocument(doc);
    setIsPanelOpen(true);
  }, []);

  const handleClosePanel = useCallback(() => {
    setSelectedDocument(null);
    setIsPanelOpen(false);
  }, []);

  useEffect(() => {
    // Check if allDocs and allFolders are empty before dispatching the action
    if (!allDocs.length && !allFolders.length) {
      dispatch(fetchFileStorage({ isInitialFetch: true }));
    }
  }, [dispatch, allDocs.length, allFolders.length]);

  useEffect(() => {
    if (isSearchActive) {
      setCurrentDocs(searchResults);
      setCurrentFolders(folderSearchResults);
    } else {
      setCurrentDocs(allDocs);
      setCurrentFolders(allFolders);
    }
  }, [allDocs, allFolders, folderSearchResults, isSearchActive, searchResults]);

  /////////////////////////// APIs Integration //////////////////////////

  ///////////////////// create new file function ////////////////////
  async function createDocument(files, id = null) {
    if (!files.length) {
      setLoadingToast({
        loading: false,
        files: 1,
      });
      return;
    }

    setLoadingToast({
      loading: true,
      files: files?.length,
    });

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    try {
      const uploadPromises = files.map(async (file) => {
        let formData = new FormData();
        formData.append("file", file);
        formData.append(id === null ? "parent_directory" : "parent_directory_id", id);
        const response = await createFile(formData, "upload", headers);
        return response.data;
      });

      const receivedData = await Promise.all(uploadPromises);

      if (id === null) {
        dispatch(updateAllDocs([...allDocs, ...receivedData]));
      }

      handleApiSuccess("Files uploaded");
      setShowUploadModal(false);
      setLoadingToast({
        loading: false,
        files: 1,
      });
      refreshResults();
    } catch (err) {
      handleApiError(
        err,
        "We were unable to create document due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
      );
      setLoadingToast({
        loading: false,
        files: 1,
      });
    }
  }

  ///////////////////// function to update a document ////////////////////
  async function updateDocument(documentId, data) {
    try {
      const { data: updatedDoc } = await updateFile(documentId, data);

      const updatedDocs = allDocs.map((doc) => (doc.id === documentId ? updatedDoc : doc));
      dispatch(updateAllDocs(updatedDocs));

      handleApiSuccess("Document updated");
    } catch (err) {
      handleApiError(
        err,
        "We were unable to update document due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
      );
    }
  }

  ///////////////////////////DRAG AND DROP FROM COMPUTER FUNCTIONALITY ////////////////////////////
  const [isDragOverDrive, setIsDragOverDrive] = useState(false);

  ////////////////// function to check validity of file
  function isValidFile(file) {
    const maxFileSize = 200000000; // 200 MB in bytes

    const acceptedFormats = {
      "text/plain": [".txt"],
      "application/pdf": [".pdf"],
      "application/doc": [".doc", ".docx", ".dot"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
      "application/pptx": [".pptx"],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
    };

    const allowedExtensions = Object.values(acceptedFormats).flat();

    if (file.size > maxFileSize) {
      handleInvalidFileMsg(
        `Document exceeds the maximum size limit. We do not support document sizes larger than ${formatBytes(
          maxFileSize
        )}. Please try again with a smaller document or contact support@vultron.ai for assistance.`
      );
      return false;
    }

    const fileExtension = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
    const fileType = file.type;

    if (!allowedExtensions.includes(fileExtension) || !acceptedFormats[fileType]?.includes(fileExtension)) {
      handleInvalidFileMsg("Document format is not supported.");
      return false;
    }

    return true;
  }

  ///// function to handle files dropped from computer into drive
  function onMainDrop(acceptedFiles) {
    if (showUploadModal) return;
    setSelectedFolderHeading("");
    setIsDragOverDrive(false);
    const validFiles = acceptedFiles.filter((file) => isValidFile(file));
    createDocument(validFiles);
  }

  ///// function to handle files dropped from computer into any folder
  function onFolderDrop(acceptedFiles, targetFolderId) {
    setSelectedFolderHeading("");
    setIsDragOverDrive(false);

    const validFiles = acceptedFiles.filter((file) => isValidFile(file));
    createDocument(validFiles, targetFolderId);
  }

  const { getRootProps: getMainRootProps, getInputProps: getMainInputProps } = useDropzone({
    onDrop: onMainDrop,
    onDragOver: (e) => {
      e.preventDefault();
      if (showUploadModal) return;
      setIsDragging(true);
      setIsDragOverDrive(true);
    },
    onDragLeave: (e) => {
      const relatedTarget = e.relatedTarget;
      if (!relatedTarget || (relatedTarget !== e.currentTarget && !e.currentTarget.contains(relatedTarget))) {
        setIsDragOverDrive(false);
        setIsDragging(false);
      }
    },

    noClick: true,
  });

  // ////////////// setting up ctrl + z //////////
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Control" || event.key === "Meta") {
        setCtrlKeyPressed(true);
      }
      if (event.key === "z" && ctrlKeyPressed) {
        undo();
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Control" || event.key === "Meta") {
        setCtrlKeyPressed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [ctrlKeyPressed]);

  // /////// perform undo method for docs & folders ///////////////////
  const undo = async () => {
    if (!stateHistory?.type) return;
    if (stateHistory?.type === "folder") {
      await moveSelectedDirectory(stateHistory?.itemInfo?.parent_directory, stateHistory?.itemInfo?.id, "prevent");
      dispatch(updateAllFolders(stateHistory?.data || []));
      setStateHistory({ type: null, data: [], itemInfo: {} });
    } else {
      await moveDocument(stateHistory?.itemInfo?.parent_directory, stateHistory?.itemInfo?.id, "prevent");
      dispatch(updateAllDocs(stateHistory?.data));
      refreshResults();
      setStateHistory({ type: null, data: [], itemInfo: {} });
    }
  };

  return (
    <div className={`relative flex-grow ${isPanelOpen ? "mr-[400px]" : ""}`} {...getMainRootProps()}>
      <input multiple {...getMainInputProps()} />
      {/* --------- DOTTED LINE APPEARS ON DRAG AND DROP ----------- */}
      <div
        className={`${
          (isDragOverDrive || selectedFolderHeading) &&
          !showUploadModal &&
          ((isDragging && !draggingFolderId && !draggingDocIds.length) || // Computer file drag
            (draggingFolderId &&
              currentFolders?.find((f) => f.id === draggingFolderId)?.id !== selectedFolderHeading) || // Folder drag
            draggingDocIds.length) // Document drag
            ? "border-[3px] border-dashed border-[#1E1E1E]"
            : ""
        } absolute w-full h-full pointer-events-none z-50`}
      />
      <div className="relative">
        {/* ---------------------------------- SECTION SEARCH -------------------------------- */}
        <section className="pt-2 pb-6 px-1">
          <div className="flex gap-3">
            <div className="flex-1 relative">
              <img src={searchIcon} alt="search icon" loading="lazy" className="absolute top-2.5 left-1.5 w-5" />

              <input
                placeholder="Search..."
                className="bg-white focus:outline-none block focus:ring-0 focus:ring-gray-200 text-sm text-gray-500 w-full h-10 py-3 pl-9 pr-3 rounded-md border border-gray-light"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            <div className="flex items-center gap-3 flex-wrap">
              <DropdownMenu multiselect items={typeItems}>
                <div className="rounded-md min-w-[140px] text-sm h-10 w-[140px] bg-white flex justify-between px-2 py-1 items-center gap-2 border border-gray-light text-slate-600">
                  <div className="truncate">
                    {documentTypes.length
                      ? documentTypes.filter((document) => !LEGACY_DOCUMENT_TYPES.includes(document)).join(", ")
                      : "Type"}
                  </div>
                  <Icon name="CarrotDown" className="min-w-3" />
                </div>
              </DropdownMenu>
              <DropdownMenu items={publishDateItemOptions}>
                <div className="rounded-md min-w-[140px] text-sm h-10 w-[140px] bg-white flex justify-between px-2 py-1 items-center gap-2 border border-gray-light text-slate-600">
                  <div className="truncate">{uploaded || "Uploaded"}</div>
                  <Icon name="CarrotDown" className="min-w-3" />
                </div>
              </DropdownMenu>
            </div>
          </div>
        </section>
        {/* ---------------------------- FOLDERS SECTION ------------------------------ */}
        <section className="folders-section min-h-[150px] px-1 pt-1">
          <div className="flex justify-between items-center mb-6">
            <h5 className="font-medium text-md">Folders</h5>
            <div
              className="flex items-center gap-1 cursor-pointer hover:bg-hover-default px-1.5 py-1 rounded-md bg-gray-100 hover:border-gray-300 border border-gray-200"
              onClick={() => openFolderModal()}
            >
              <span className="text-sm font-semibold">Folder</span>
              <Plus size={14} />
            </div>
          </div>

          {isInitialFetch && !currentFolders.length ? (
            <div className="w-full flex justify-center flex-col py-4 px-2">
              <img className="mx-auto max-w-full" src={lod} alt="Loading..." />
              <div className="flex-grow" />
            </div>
          ) : currentFolders.length ? (
            <div className="cards grid grid-cols-4 2xl:flex 2xl:flex-wrap gap-x-[20px] gap-y-5 mb-8">
              {currentFolders.map((folder) => (
                <Folder
                  key={folder.id}
                  {...folder}
                  draggingFolderId={draggingFolderId}
                  setDraggingFolderId={setDraggingFolderId}
                  draggingDocIds={draggingDocIds}
                  setDraggingDocIds={setDraggingDocIds}
                  moveSelectedDirectory={moveSelectedDirectory}
                  handleDelete={deleteSelectedDirectory}
                  moveDocument={moveDocument}
                  createDocument={createDocument}
                  setSelectedFolderHeading={setSelectedFolderHeading}
                  setIsDragOverDrive={setIsDragOverDrive}
                  selectedFolderHeading={selectedFolderHeading}
                  onFolderDrop={onFolderDrop}
                  handleMoveModalOpen={() => {
                    handleMoveModalOpen(folder.id, "folder");
                  }}
                  openFolderModal={openFolderModal}
                />
              ))}
            </div>
          ) : (
            !isSearchActive && (
              <div className="pb-10">
                <h5 className="text-base text-center font-semibold text-[#1D2630]">No Folders</h5>
                <p className="text-[#353535] text-center pt-[5px]">
                  Create a folder by clicking on the “Folder +” button.
                </p>
              </div>
            )
          )}

          <CustomModal isOpen={folderModal.isOpen} onClose={closeFolderModal}>
            <div className="p-5 w-[444px]">
              <div className="flex justify-between items-center mb-5">
                <h3 className="font-semibold text-xl">
                  {folderModal.selectedFolderId ? "Update Folder" : "New Folder"}
                </h3>
                <div className="w-[15.56px] h-[15.56px]">
                  <span className="cursor-pointer">
                    <CloseIconBlack onClick={closeFolderModal} />
                  </span>
                </div>
              </div>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  folderModal.selectedFolderId ? updateFolder() : createFolder();
                }}
              >
                <label className="mb-2 block text-sm text-[#5B6B79]">Name</label>
                <input
                  type="text"
                  className="w-full rounded-md py-2 px-3 h-10 border-[1px] border-[#CFD1D4] focus:ring-gray-darkest focus:border-gray-darkest outline-none mb-7"
                  value={folderModal.folderName}
                  onChange={(e) => updateFolderName(e.target.value)}
                  autoFocus
                  required
                />

                <div className="flex justify-end gap-4">
                  <button
                    type="button"
                    className="border-[1px] border-[#DBE0E5] rounded-lg py-[9px] px-4 text-sm font-medium text-[#1D2630]"
                    onClick={closeFolderModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="border-0 bg-gray-darkest rounded-lg py-[9px] px-4 text-sm font-medium text-[#ffffff] flex items-center gap-2"
                  >
                    {folderModal.selectedFolderId ? "Update" : "Add"}
                  </button>
                </div>
              </form>
            </div>
          </CustomModal>
        </section>
        {/* -------------------------- SECTION DOCUMENT -------------------------------- */}
        <section className="document-section px-1 relative mb-9" ref={containerRef}>
          <div className="flex items-center justify-between mb-3">
            <div className="flex flex-col gap-3">
              <h5 className="font-medium text-md mb-4">Documents</h5>
              {/* {!!currentDocs.length && !isSearching && (
                <div className="flex items-center">
                  <Checkbox
                    variant="primaryBlack"
                    checked={currentDocs.length > 0 && selectedDocuments.length === currentDocs.length}
                    onCheck={(checked) => setSelectedDocuments(checked ? currentDocs.map((doc) => doc.id) : [])}
                  />
                  <span className="ml-2 text-sm text-gray-mid">Select All</span>
                </div>
              )} */}
            </div>
            {!isSearchActive && (
              <div
                onClick={() => setShowUploadModal(true)}
                className="flex items-center gap-1 rounded-md cursor-pointer px-1.5 py-1  bg-gray-100 hover:border-gray-300 border border-gray-200"
              >
                <span className="text-sm font-semibold">Document</span>
                <span className="relative block cursor-pointer">
                  <Plus size={14} />
                </span>
              </div>
            )}
          </div>

          {isInitialFetch || isSearching ? (
            <div className="w-full flex justify-center flex-col py-14 px-2">
              <img className="mx-auto max-w-full" src={lod} alt="Loading..." />
              <div className="flex-grow" />
            </div>
          ) : (
            <>
              {!!currentDocs.length && (
                <div className="cards grid grid-cols-[repeat(auto-fill,_258px)] round-lg gap-5">
                  {currentDocs.map((doc, i) => (
                    <Document
                      key={i + doc?.id}
                      docData={doc}
                      deleteDocument={deleteDocument}
                      setDraggingFolderId={setDraggingFolderId}
                      setDraggingDocIds={setDraggingDocIds}
                      handleMoveModalOpen={handleMoveModalOpen}
                      onSelect={handleDocumentSelect}
                      isSelected={selectedDocuments.includes(doc.id)}
                      selectedDocuments={selectedDocuments}
                      onDocumentClick={() => handleDocumentClick(doc)}
                      isDocumentPanelOpen={selectedDocument?.id === doc.id}
                    />
                  ))}
                </div>
              )}
              {/* -------------- CONTENT LIBRARY PANEL ---------------- */}
              {selectedDocument && (
                <ContentLibraryPanel
                  doc={selectedDocument}
                  isOpen={isPanelOpen}
                  onClose={handleClosePanel}
                  onUpdate={updateDocument}
                />
              )}
              {isSearchActive && !currentDocs.length && (
                <NotFoundItems
                  title="No documents found"
                  subTitle="No matching results. Try another search."
                  className={"w-full flex mt-24 justify-center items-center bg-inherit"}
                />
              )}
              {!isSearchActive && (
                <div className="mb-5">
                  <UploadDriveDocModal isDrive={!currentDocs.length} createDocument={createDocument} />
                </div>
              )}
            </>
          )}

          {/* ------------ UPLOAD files MODAL --------------- */}
          <UploadDriveDocModal
            showUploadModal={showUploadModal}
            setShowUploadModal={setShowUploadModal}
            createDocument={createDocument}
          />
        </section>
      </div>
      {/* ------------- Drag and Drop Toast -------------------- */}
      {(isDragOverDrive || selectedFolderHeading) && !showUploadModal && (
        <div className="fixed bottom-24 w-full pointer-events-none z-50">
          {/* Don't show toast when trying to move folder to itself */}
          {!(
            !isDragging &&
            draggingFolderId &&
            selectedFolderHeading &&
            currentFolders.find((f) => f.id === draggingFolderId)?.id === selectedFolderHeading
          ) && (
            <div className="fixed left-1/2 transform -translate-x-1/2 bg-gray-darkest py-3.5 px-16 rounded-full text-center">
              <span className="text-lg opacity-90 text-[#ffffff] block mb-1.5">
                {(() => {
                  if (draggingFolderId) {
                    return "Move folder to";
                  } else if (draggingDocIds.length) {
                    return `Move ${pluralizeWord(draggingDocIds.length, "document")} to`;
                  } else {
                    return "Drop documents to upload them to";
                  }
                })()}
              </span>

              <span className="text-lg font-semibold text-[#ffffff] flex justify-center items-center gap-2">
                <img
                  src={selectedFolderHeading ? folderOpenSm : driveWhite}
                  alt="icon"
                  loading="lazy"
                  className="w-[18px] h-[18px] bg-[#fffffff]"
                />
                {currentFolders.find((f) => f.id === selectedFolderHeading)?.name || "Content Library"}
              </span>
            </div>
          )}
        </div>
      )}
      {/* ---------- Loading toast new ---------- */}
      {loadingToast?.loading && (
        <ExtractingDocumentToast
          loadingText={`Uploading ${loadingToast?.files > 1 ? "Documents" : "Document"}`}
          handleClose={() => setLoadingToast({ loading: false, files: 1 })}
        />
      )}
      {/* ------------ Move Modal --------------- */}
      <MoveModal
        selectedItems={moveModalOpen.items}
        handleCloseMoveModal={handleCloseMoveModal}
        onMove={onMoveManually}
        currentFiles={currentDocs}
        isMain={true}
      />
      {!selectedFolderHeading && selectedDocuments.length > 0 && (
        <SelectedDocumentsToolbar
          selectedCount={selectedDocuments.length}
          onClearSelection={() => setSelectedDocuments([])}
          onMove={handleMoveSelected}
          onDelete={handleDeleteSelected}
        />
      )}
    </div>
  );
};

export default DocumentsContent;
