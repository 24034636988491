import { Section, Volume } from "components/copilot/CopilotSchemaTypes";
import { TemplateSection, TemplateVolume } from "types/TemplateSection";
import { createSection, createVolume } from "utils/framework";
import { LiveList, LiveObject } from "YJSProvider/LiveObjects";

export const transformTemplateVolumesToYJS = (volumes: TemplateVolume[]): LiveObject<Volume>[] => {
  const transformSections = (sections: TemplateSection[]) =>
    sections?.reduce<LiveObject<Section>[]>((acc, { id, title, subsections = [] }) => {
      const { id: parentId, item: createdSection } = createSection({ id, title });

      const createdSubsections = subsections.map(
        (subsection) => createSection({ id: subsection.id, title: subsection.title, parent_id: parentId }).item
      );
      return [...acc, createdSection, ...createdSubsections];
    }, []) || [];

  const liveVolumes = volumes.map(({ id, title, sections }) => {
    const createdVolume = createVolume({ id, title, sections: new LiveList(transformSections(sections)) });
    return createdVolume;
  });

  return liveVolumes;
};
