import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { moveFiles, moveDirectory, deleteDirectory, deleteFiles } from "api/api";
import { updateAllDocs, updateAllFolders } from "store/reducers/driveReducerSlice";
import { WorkspaceFile, Subdirectory } from "types/FileStorage";
import { useOperationsUtils } from "./useOperationsUtils";
import { pluralizeWord } from "utils/string";

interface MoveModalState {
  items: string[] | null;
  open: boolean;
  type: "doc" | "folder" | null;
}

interface StateHistory {
  type: "doc" | "folder" | null;
  data: WorkspaceFile[];
  itemInfo: WorkspaceFile | undefined;
}

export const useFileOperations = (
  allDocs: WorkspaceFile[],
  allFolders: Subdirectory[],
  refreshResults: () => void,
  setStateHistory: (history: StateHistory) => void,
  setSelectedDocuments: (selectedDocs: string[]) => void
) => {
  const dispatch = useDispatch();
  const [moveModalOpen, setMoveModalOpen] = useState<MoveModalState>({ items: null, open: false, type: null });
  const { handleApiError, handleApiSuccess } = useOperationsUtils();

  const moveDocument = useCallback(
    async (dropTargetFolderId: string, selectedDocIds: string | string[], preventHistory?: boolean) => {
      try {
        const docIds = Array.isArray(selectedDocIds) ? selectedDocIds : [selectedDocIds];
        await moveFiles(docIds, { parent_directory_id: dropTargetFolderId === "drive" ? null : dropTargetFolderId });
        if (!preventHistory) {
          setStateHistory({
            type: "doc",
            data: allDocs,
            itemInfo: allDocs.find((doc) => docIds.includes(doc.id)),
          });
        }
        const updatedDocs = allDocs.filter((doc) => !docIds.includes(doc.id));
        dispatch(updateAllDocs(updatedDocs));
        refreshResults();
        setSelectedDocuments([]);
        handleApiSuccess(`${docIds.length} ${pluralizeWord(docIds.length, "Document")} moved`);
      } catch (err) {
        handleApiError(
          err,
          "We were unable to move the document(s) due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
        );
      }
    },
    [allDocs, dispatch, refreshResults, setSelectedDocuments, handleApiSuccess, setStateHistory, handleApiError]
  );

  const moveFolder = useCallback(
    async (dropTargetFolderId: string, selectedFolderId: string, preventHistory?: boolean) => {
      try {
        await moveDirectory(selectedFolderId, {
          parent_directory_id: dropTargetFolderId === "drive" ? null : dropTargetFolderId,
        });
        const updatedFolders = allFolders.filter((folder) => folder.id !== selectedFolderId);
        dispatch(updateAllFolders(updatedFolders));
        refreshResults();
        handleApiSuccess(`Folder moved`);
      } catch (err) {
        handleApiError(
          err,
          "We were unable to move the folder due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
        );
      }
    },
    [allFolders, dispatch, refreshResults, handleApiSuccess, handleApiError]
  );

  const deleteDocument = useCallback(
    async (documentIds: string | string[]) => {
      try {
        const ids = Array.isArray(documentIds) ? documentIds : [documentIds];
        await deleteFiles(ids);
        const updatedDocs = allDocs.filter((doc) => !ids.includes(doc.id));
        dispatch(updateAllDocs(updatedDocs));
        setSelectedDocuments([]);
        refreshResults();
        handleApiSuccess(`${ids.length} ${pluralizeWord(ids.length, "Document")} deleted`);
      } catch (err) {
        handleApiError(
          err,
          "We were unable to delete the document(s) due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
        );
      }
    },
    [allDocs, dispatch, setSelectedDocuments, refreshResults, handleApiSuccess, handleApiError]
  );

  const deleteFolder = useCallback(
    async (folderId: string) => {
      try {
        await deleteDirectory(folderId);
        const updatedFolders = allFolders.filter((folder) => folder.id !== folderId);
        dispatch(updateAllFolders(updatedFolders));
        refreshResults();
        handleApiSuccess(`Folder deleted`);
      } catch (err) {
        handleApiError(
          err,
          "We were unable to delete the folder due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
        );
      }
    },
    [allFolders, dispatch, refreshResults, handleApiSuccess, handleApiError]
  );

  const handleMoveModalOpen = useCallback((items: string | string[], type: "doc" | "folder" | null) => {
    setMoveModalOpen({ items: Array.isArray(items) ? items : [items], open: true, type });
  }, []);

  const handleCloseMoveModal = useCallback(() => {
    setMoveModalOpen({ items: null, open: false, type: null });
  }, []);

  const onMoveManually = useCallback(
    async (selectedDestination: string) => {
      if (!moveModalOpen.items || moveModalOpen.items.length === 0) return;

      if (moveModalOpen.type === "folder") {
        await moveFolder(selectedDestination, moveModalOpen.items[0]);
      } else {
        await moveDocument(selectedDestination, moveModalOpen.items);
      }
      handleCloseMoveModal();
      refreshResults();
    },
    [moveDocument, moveFolder, moveModalOpen, handleCloseMoveModal, refreshResults]
  );

  return {
    moveDocument,
    moveFolder,
    deleteDocument,
    deleteFolder,
    moveModalOpen,
    handleMoveModalOpen,
    handleCloseMoveModal,
    onMoveManually,
  };
};
