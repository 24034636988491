import { PlusIcon, X } from "lucide-react";
import ClickAwayListener from "helpers/ClickAwayListener";
import { useRef, useEffect } from "react";

interface TagsSectionProps {
  tags: string[];
  isAddingTag: boolean;
  setIsAddingTag: (value: boolean) => void;
  newTag: string;
  setNewTag: (value: string) => void;
  handleAddTag: (e: React.FormEvent) => void;
  handleRemoveTag: (tag: string) => void;
}

export const TagsSection = ({
  tags,
  isAddingTag,
  setIsAddingTag,
  newTag,
  setNewTag,
  handleAddTag,
  handleRemoveTag,
}: TagsSectionProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isAddingTag && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isAddingTag]);

  return (
    <div className="ml-6 mr-6">
      <div className="flex items-center justify-between mb-4">
        <div className="text-sm font-medium">Tags</div>
        <button className="hover:bg-gray-100 p-1 rounded-full" onClick={() => setIsAddingTag(true)}>
          <PlusIcon color="#7A7F84" size={16} />
        </button>
      </div>
      <div className="flex flex-wrap gap-2 relative my-2">
        {tags.map((tag, index) => (
          <div
            key={index}
            className="relative inline-flex items-center rounded bg-[#F4F5F6] px-2 py-[4.5px] text-sm group"
          >
            {tag}
            <button
              onClick={() => handleRemoveTag(tag)}
              className="absolute -top-1 -right-1 rounded-full bg-gray-400 hover:bg-gray-500 w-4 h-4 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity"
            >
              <X size={12} className="text-white" />
            </button>
          </div>
        ))}

        {isAddingTag && (
          <ClickAwayListener
            onClickAway={() => {
              setIsAddingTag(false);
              setNewTag("");
            }}
          >
            <div className="absolute top-full left-0 mt-1 bg-white rounded-lg shadow-lg border p-3 z-10">
              <form onSubmit={handleAddTag}>
                <input
                  ref={inputRef}
                  type="text"
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  onKeyDown={(e) => e.key === "Escape" && setIsAddingTag(false)}
                  placeholder="Add tag..."
                  className="w-full px-2 py-1.5 text-sm border rounded focus:outline-none"
                />
              </form>
              <div className="mt-2 text-xs text-gray-500">Press Enter to add, Esc to cancel</div>
            </div>
          </ClickAwayListener>
        )}
      </div>
    </div>
  );
};
