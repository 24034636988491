import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { createDirectory, updateDirectory, deleteDirectory, moveDirectory } from "api/api";
import { updateAllFolders } from "store/reducers/driveReducerSlice";
import { Subdirectory } from "types/FileStorage";
import { validateField } from "utils/helpers";
import { useOperationsUtils } from "./useOperationsUtils";
import { fetchFileStorage } from "store/reducers/driveReducerSlice";

interface FolderModalState {
  isOpen: boolean;
  selectedFolderId: string | null;
  folderName: string;
}

export const useFolderOperations = (
  allFolders: Subdirectory[],
  refreshResults: () => void,
  setStateHistory: (history: { type: string | null; data: any[]; itemInfo: any }) => void,
  options: {
    parentFolderId?: string;
    setAllFolders?: (folders: Subdirectory[]) => void;
    isRootLevel?: boolean;
  } = {}
) => {
  const { parentFolderId, setAllFolders, isRootLevel } = options;
  const dispatch = useDispatch();
  const { handleApiError, handleApiSuccess } = useOperationsUtils();
  const [folderModal, setFolderModal] = useState<FolderModalState>({
    isOpen: false,
    selectedFolderId: null,
    folderName: "",
  });

  const updateFolderState = useCallback(
    (updatedFolders: Subdirectory[]) => {
      if (isRootLevel) {
        dispatch(updateAllFolders(updatedFolders));
      } else if (setAllFolders) {
        setAllFolders(updatedFolders);
      }
    },
    [dispatch, isRootLevel, setAllFolders]
  );

  const createFolder = useCallback(async () => {
    if (!validateField(folderModal.folderName)) return;

    try {
      const { data: newFolder } = await createDirectory({
        name: folderModal.folderName,
        parent_directory_id: parentFolderId || null,
      });

      const updatedFolders = [...allFolders, newFolder];
      updateFolderState(updatedFolders);
      handleApiSuccess("Folder created");
      return newFolder;
    } catch (err) {
      handleApiError(
        err,
        "We were unable to create a new folder due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
      );
      return null;
    } finally {
      setFolderModal((prev) => ({ ...prev, isOpen: false, folderName: "" }));
    }
  }, [allFolders, folderModal.folderName, handleApiError, handleApiSuccess, parentFolderId, updateFolderState]);

  const updateFolder = useCallback(async () => {
    if (!validateField(folderModal.folderName) || !folderModal.selectedFolderId) return;

    try {
      await updateDirectory(folderModal.selectedFolderId, {
        name: folderModal.folderName,
      });

      const updatedFolders = allFolders.map((folder) =>
        folder.id === folderModal.selectedFolderId ? { ...folder, name: folderModal.folderName } : folder
      );

      updateFolderState(updatedFolders);
      dispatch(fetchFileStorage());
      handleApiSuccess("Folder updated");
      return true;
    } catch (err) {
      handleApiError(
        err,
        "We were unable to update the folder due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
      );
      return false;
    } finally {
      setFolderModal((prev) => ({ ...prev, isOpen: false, folderName: "", selectedFolderId: null }));
    }
  }, [
    allFolders,
    folderModal.folderName,
    folderModal.selectedFolderId,
    handleApiError,
    handleApiSuccess,
    updateFolderState,
  ]);

  const deleteFolder = useCallback(
    async (folderId: string) => {
      try {
        await deleteDirectory(folderId);
        const updatedFolders = allFolders.filter((folder) => folder.id !== folderId);
        updateFolderState(updatedFolders);
        handleApiSuccess("Folder deleted");
        refreshResults();
        return true;
      } catch (err) {
        handleApiError(
          err,
          "We were unable to delete the folder due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
        );
        return false;
      }
    },
    [allFolders, handleApiError, handleApiSuccess, refreshResults, updateFolderState]
  );

  const moveFolder = useCallback(
    async (dropTargetFolderId: string, selectedFolderId: string, preventHistory?: boolean) => {
      try {
        await moveDirectory(selectedFolderId, {
          parent_directory_id: dropTargetFolderId === "drive" ? null : dropTargetFolderId,
        });

        if (!preventHistory) {
          setStateHistory({
            type: "folder",
            data: allFolders,
            itemInfo: allFolders?.find((folder) => folder?.id === selectedFolderId),
          });
        }

        const updatedFolders = allFolders.filter((folder) => folder?.id !== selectedFolderId);
        updateFolderState(updatedFolders);
        refreshResults();
        handleApiSuccess("Folder moved");
        return true;
      } catch (err) {
        handleApiError(
          err,
          "We were unable to move the folder due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
        );
        return false;
      }
    },
    [allFolders, handleApiError, handleApiSuccess, refreshResults, setStateHistory, updateFolderState]
  );

  const openFolderModal = useCallback((folderId?: string, initialName: string = "") => {
    setFolderModal({
      isOpen: true,
      selectedFolderId: folderId || null,
      folderName: initialName,
    });
  }, []);

  const closeFolderModal = useCallback(() => {
    setFolderModal({
      isOpen: false,
      selectedFolderId: null,
      folderName: "",
    });
  }, []);

  const updateFolderName = useCallback((name: string) => {
    setFolderModal((prev) => ({ ...prev, folderName: name }));
  }, []);

  return {
    folderModal,
    createFolder,
    updateFolder,
    deleteFolder,
    moveFolder,
    openFolderModal,
    closeFolderModal,
    updateFolderName,
  };
};
