import { AlertTriangle } from "lucide-react";
import { ChevronDown } from "lucide-react";
import DatePicker from "react-multi-date-picker";
import Avatar from "components/molecules/avatar";
import { CalenderIcon } from "utils/icons";
import ClickAwayListener from "helpers/ClickAwayListener";
import { DOCUMENT_TYPES, LEGACY_DOCUMENT_TYPES } from "pages/drive/documents/constants";
import { ReviewStatus } from "components/Drive/getReviewStatus";
import Tooltip from "components/atoms/tooltip/Tooltip";

interface PropertiesSectionProps {
  isMediaFile?: boolean;
  uploaderUsername: string;
  uploaderId: string;
  date: string;
  handleDateChange: (date: any) => void;
  // Review Date props
  status: {
    color: string;
    text: string;
  };
  reviewStatus: ReviewStatus;
  reviewDate: string;
  handleReviewDateChange: (date: any) => void;
  // DocumentType props
  isClassificationDropdown: boolean;
  setIsClassificationDropdown: (value: boolean) => void;
  selectedClassification: string;
  handleClassificationSelect: (type: string) => void;
}

export const PropertiesSection = ({
  status,
  uploaderUsername: uploaderUsername,
  uploaderId,
  date,
  handleDateChange,
  reviewDate,
  handleReviewDateChange,
  reviewStatus,
  isClassificationDropdown,
  setIsClassificationDropdown,
  selectedClassification,
  handleClassificationSelect,
  isMediaFile,
}: PropertiesSectionProps) => {
  const normalizeClassificationLabel = (classification: string) => {
    return classification
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const filteredAndNormalizedDocumentTypes = Object.keys(DOCUMENT_TYPES).filter(
    (type) => !LEGACY_DOCUMENT_TYPES.includes(type)
  );

  return (
    <div className="ml-6 mr-6">
      <div className="space-y-4">
        <div className="text-sm font-medium">Properties</div>
        <div className="grid grid-cols-[120px_1fr] gap-y-4 items-center text-sm text-[#7A7F84]">
          {!isMediaFile && (
            <>
              <div>Status</div>
              <div className={`${status.color}`}>{status.text}</div>
            </>
          )}

          <div>Uploader</div>
          <div className="text-sm font-medium flex flex-row gap-2">
            <Avatar size={18} textColor={"#FFFFFF"} name={uploaderUsername} id={uploaderId} />
            {uploaderUsername}
          </div>

          <div>Date</div>
          <DatePicker
            format="MMMM DD, YYYY"
            value={date}
            onChange={handleDateChange}
            containerClassName="!w-full !max-w-full"
            onOpenPickNewDate={false}
            render={(value, openCalendar) => (
              <div
                onClick={openCalendar}
                tabIndex={0}
                className="relative text-gray-mid py-2 cursor-pointer select-none w-48 px-4 overflow-hidden border text-sm rounded-md focus:ring-gray-darkest focus:border-gray-darkest block"
              >
                <div className="absolute inset-y-0 right-3 flex items-center cursor-pointer select-none">
                  <CalenderIcon />
                </div>
                <p>{date || "Set Date"}</p>
              </div>
            )}
          />

          {!isMediaFile && (
            <>
              <div>Review Date</div>
              <div className="flex flex-row items-center">
                <DatePicker
                  format="MMMM DD, YYYY"
                  value={reviewDate}
                  onChange={handleReviewDateChange}
                  containerClassName="!w-full !max-w-full"
                  onOpenPickNewDate={false}
                  render={(value, openCalendar) => (
                    <div
                      onClick={openCalendar}
                      tabIndex={0}
                      className="relative text-gray-mid py-2 cursor-pointer select-none w-48 px-4 overflow-hidden border text-sm rounded-md focus:ring-gray-darkest focus:border-gray-darkest block"
                    >
                      <div className="absolute inset-y-0 right-3 flex items-center cursor-pointer select-none">
                        <CalenderIcon />
                      </div>
                      <p style={{ color: reviewStatus.needsReview ? reviewStatus.color : "" }}>
                        {reviewDate || "Set Review Date"}
                      </p>
                    </div>
                  )}
                />
                {reviewStatus.needsReview && (
                  <Tooltip
                    contentProps={{ alignOffset: -78, align: "start" }}
                    content={
                      <div className="text-gray-darkest">
                        <span className="font-normal">{reviewStatus.tooltipText}</span>
                      </div>
                    }
                  >
                    <AlertTriangle style={{ color: reviewStatus.color }} />
                  </Tooltip>
                )}
              </div>
            </>
          )}

          {!isMediaFile && (
            <>
              <div>Type</div>
              <ClickAwayListener onClickAway={() => setIsClassificationDropdown(false)}>
                <div className="relative">
                  <button
                    onClick={() => setIsClassificationDropdown(!isClassificationDropdown)}
                    className="relative py-2 w-48 px-4 h-10 text-left text-gray-mid bg-white border rounded-md text-sm outline-none focus:ring-gray-darkest focus:border-gray-darkest"
                  >
                    {normalizeClassificationLabel(selectedClassification) || "Select Classification"}
                    <div className="absolute inset-y-0 right-3 flex items-center cursor-pointer select-none">
                      <ChevronDown />
                    </div>
                  </button>

                  {isClassificationDropdown && (
                    <div className="absolute h-40 overflow-y-auto w-full mt-1 bg-white border rounded-lg shadow-[0_9px_28px_#00000014] py-1 z-10">
                      {filteredAndNormalizedDocumentTypes.map((type) => (
                        <button
                          key={type}
                          onClick={() => handleClassificationSelect(type)}
                          className="block w-full px-3 py-2 text-left text-sm text-[#1E1E1E] hover:bg-gray-200 focus:outline-none"
                        >
                          {normalizeClassificationLabel(type)}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </ClickAwayListener>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
