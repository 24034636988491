import { Editor, useEditor } from "@tiptap/react";
import { useCallback, useEffect, useRef } from "react";
import Document from "@tiptap/extension-document";
import Text from "@tiptap/extension-text";

import { OnLineChangePlugin } from "../plugins/OnLineChangePlugin";
import { scrollToTemplateManager } from "../../utils";
import { RestrictedHeading } from "../plugins/RestrictedHeading";
import type { EditorActive, OnOutlineEditorChange, OutlineContent } from "../types";
import { EnterHandler } from "../plugins/EnterHandler";
import { debounce, isEqual } from "lodash";
import { ClipboardHandler } from "../plugins/ClipboardHandler";
import { fixRequirements, fixTree } from "./utils";
import History from "@tiptap/extension-history";
import DragHandle from "@tiptap-pro/extension-drag-handle";
import NodeRange from "@tiptap-pro/extension-node-range";
import Dropcursor from "@tiptap/extension-dropcursor";
import Gapcursor from "@tiptap/extension-gapcursor";
import { TextSelection } from "@tiptap/pm/state";
import type { Level } from "@tiptap/extension-heading";
import { ComplianceMatrixRow } from "components/copilot/CopilotSchemaImmutableTypes";
import { useAppSelector } from "store/storeTypes";

function setEditorContent(editor: Editor | null, content: OutlineContent[]) {
  if (!editor) {
    return;
  }

  const { from, to } = editor.state.selection;
  editor.commands.setContent(
    {
      type: "doc",
      content,
    },
    false
  );
  editor.view.dispatch(
    editor.state.tr.setSelection(TextSelection.create(editor.state.doc, from, to)).setMeta("addToHistory", false)
  );
}

type Props = {
  content: OutlineContent[];
  onChange: OnOutlineEditorChange;
  version: number;
  isReadOnly: boolean;
  maxLevel: Level;
};

export function useEditorState({ content, onChange, version, isReadOnly, maxLevel }: Props): {
  editor: Editor | null;
  active: EditorActive;
} {
  const appliedVersion = useRef(0);
  const editorActive = useRef<EditorActive>({ line: 0, nodes: [] });
  const matrix = useAppSelector(
    (root) => root.currentExtractionState.currentExtraction?.compliance_matrix as ComplianceMatrixRow[] | undefined
  );

  const onImmediateUpdate = useCallback(
    ({ editor }: { editor: Editor | null }) => {
      if (!editor || isReadOnly) {
        return;
      }
      const editorContent = editor.getJSON().content as OutlineContent[];

      if (isEqual(content, editorContent)) {
        console.log("no changes detected, skipping update");
        return;
      }
      const [isFixed, fixedContent] = fixTree(content, editorContent, maxLevel);

      if (isFixed) {
        console.warn(`Incorrect state detected, patch applied`);
        setEditorContent(editor, fixedContent);
        // We return since the editor update will already trigger a new transaction
        return;
      }
      const complianceMatrixChanges = fixRequirements(content, fixedContent, matrix);
      appliedVersion.current += 1;
      onChange(fixedContent, complianceMatrixChanges, appliedVersion.current);
    },
    [onChange, content, isReadOnly, matrix, maxLevel]
  );
  const onUpdate = useCallback(debounce(onImmediateUpdate, 300), [onImmediateUpdate]);

  const editor = useEditor({
    editable: !isReadOnly,
    extensions: [
      ClipboardHandler.configure({
        maxLevel,
      }),
      EnterHandler,
      Document,
      Text,
      History,
      RestrictedHeading.configure({
        maxLevel,
      }),
      Gapcursor,
      NodeRange,
      ...(isReadOnly
        ? []
        : [
            Dropcursor.configure({
              color: "rgb(96, 165, 250)",
              width: 2,
            }),
            DragHandle.configure({
              render() {
                const element = document.createElement("div");
                element.classList.add("custom-drag-handle");
                return element;
              },
              onNodeChange() {
                onUpdate({ editor });
              },
            }),
          ]),
      OnLineChangePlugin.configure({
        onChange: (line, nodes) => {
          const attrs = nodes?.[0]?.node?.attrs;
          if (!attrs) {
            // skip, no node selected
          } else if (attrs.level === 1) {
            scrollToTemplateManager(`template-manager-volume-${attrs.xid}`);
          } else {
            scrollToTemplateManager(`template-manager-section-${attrs.xid}`);
          }
          editorActive.current = { line, nodes };
        },
      }),
    ],
    content: {
      type: "doc",
      content,
    },
    onUpdate,
  });

  useEffect(() => {
    if (editor) {
      editor.commands.setContent(
        {
          type: "doc",
          content,
        },
        false
      );
    }
  }, [editor]);

  useEffect(() => {
    if (!editor) {
      return;
    }

    // Skip update if the version is behind or the same than the current version
    if (version && appliedVersion.current && version <= appliedVersion.current) {
      return;
    }

    setEditorContent(editor, content);

    // Only set version if it has already been initialized or if drafts have previously loaded
    // Otherwise we may end up setting the version before drafts actually load and prevent updates.
    if (appliedVersion.current || content.length) {
      appliedVersion.current = version;
    }
  }, [editor, content]); // intentionally ignore version field since it's only updated based on contents

  return { editor, active: editorActive.current };
}
