import VoiceTranslateItem from "components/voice-assist/VoiceTranslateItem";
import { ArrowDown, ArrowRight, Square } from "lucide-react";
import { useAskAi } from "./hooks";
import { RiSparklingFill } from "@remixicon/react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import AiThinking from "Assets/gifs/ai-thinking.gif";
import { useLayoutEffect, useRef, useState } from "react";
import TypingEffect from "components/TypingEffect";
import tw from "twin.macro";
import { useAnimateLoadingMsg } from "hook/useAnimateLoadingMsg";
import { INITIAL_LOADING_MSG, LOADING_MSGS } from "./constants";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { setShouldTriggerJump, toggleAskAiOpen } from "store/reducers/extract/CurrentExtractionReducer";

type Props = {
  requirementIds?: string[];
};

const AskAiForm = ({ requirementIds }: Props) => {
  const { handleAskAi, isLoading, setQuery, query, answer, isError, abortConnection, resetForm } = useAskAi();
  const [loadingMsg, setLoadingMsg] = useState(INITIAL_LOADING_MSG);
  const [autoScroll, setAutoScroll] = useState(true);
  const scrollRef = useRef<HTMLDivElement>(null);
  const filteredRequirements = useAppSelector((store) => store.currentExtractionState.filteredRequirements);
  const askAiOpen = useAppSelector((store) => store.currentExtractionState.askAiOpen);
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement | null>(null);
  useAnimateLoadingMsg(isLoading && !answer, 8000, LOADING_MSGS, (msg) => setLoadingMsg(msg));

  useLayoutEffect(() => {
    if (autoScroll) {
      if (
        (scrollRef.current?.scrollHeight || 0) - (scrollRef.current?.clientHeight || 0) >=
        (scrollRef.current?.scrollTop || 0)
      ) {
        setTimeout(() => {
          scrollRef.current?.scrollTo(0, scrollRef.current?.scrollHeight + 21);
        }, 0);
      }
    }
  }, [autoScroll, answer]);

  useLayoutEffect(() => {
    if (askAiOpen) setTimeout(() => inputRef.current?.focus(), 300);
  }, [askAiOpen]);

  return (
    <div className="flex flex-col border-t overflow-hidden border-gray-lightest bg-white shadow-[0px_-2px_6px_rgba(0,0,0,0.15)]">
      <div className="flex items-center gap-3 px-4">
        <RiSparklingFill size={16} />
        <input
          ref={inputRef}
          onKeyDown={(e) => {
            if (isLoading) return;
            if (e.code === "Enter" && !e.shiftKey) {
              handleAskAi({ ...(!!requirementIds?.length && { requirement_ids: requirementIds }) });
            }
          }}
          autoFocus
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Ask Vultron anything..."
          className="text-sm py-4 pr-2 flex-1 outline-none truncate"
        />
        <div className="flex flex-row gap-2 items-center">
          <span className="flex items-end h-full gap-2">
            <VoiceTranslateItem disabled={isLoading} onComplete={(val) => setQuery(val)} />
          </span>
          <button
            disabled={!isLoading && !query.trim()}
            className="bg-black text-sm flex items-center justify-center text-white w-[32px] min-w-[32px] h-[32px] min-h-[32px] duration-150 rounded-lg hover:bg-zinc-700 disabled:bg-gray-200 disabled:text-slate-400 disabled:cursor-default"
            onClick={() => {
              if (isLoading) {
                abortConnection();
              } else {
                handleAskAi({ ...(!!requirementIds?.length && { requirement_ids: requirementIds }) });
              }
            }}
          >
            {isLoading ? <Square size={16} className="fill-current" /> : <ArrowRight size={20} />}
          </button>
        </div>
      </div>
      {(isLoading || answer || isError) && (
        <div
          ref={scrollRef}
          className="border-t border-gray-light p-4 overflow-y-auto"
          onScroll={(e) => {
            const shouldAutoScroll =
              e.currentTarget.scrollTop + 1 > e.currentTarget.scrollHeight - e.currentTarget.clientHeight;

            if (shouldAutoScroll !== autoScroll) setAutoScroll(shouldAutoScroll);
          }}
        >
          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-between">
              <label className="text-xs font-medium text-gray-600">Answer</label>
              {!isLoading && (
                <div className="flex flex-row gap-2 items-center">
                  <div className="text-xs text-gray-500">
                    {filteredRequirements.length} Result{filteredRequirements.length !== 1 && "s"}
                  </div>
                  {!!filteredRequirements.length && (
                    <button
                      onClick={() => dispatch(setShouldTriggerJump(true))}
                      className="text-gray-600 underline text-xs duration-100 hover:underline"
                    >
                      View results
                    </button>
                  )}
                  <button onClick={resetForm} className="text-red-600 underline text-xs duration-100 hover:underline">
                    Reset
                  </button>
                  <button
                    onClick={() => dispatch(toggleAskAiOpen())}
                    className="underline text-xs duration-100 hover:underline"
                  >
                    Close
                  </button>
                </div>
              )}
            </div>
            {isLoading && !answer && (
              <div className="text-sm text-gray-600">
                <img className="w-[44px] h-[44px] inline" src={AiThinking} alt="ai thinking" /> {loadingMsg}
                <span className="loading-ellipsis" />
              </div>
            )}
            {!isLoading && isError && (
              <div className="text-sm text-gray-600 py-3">
                No relevant information was found for your request. Please provide additional context or specific
                details to refine the search.
              </div>
            )}
            {answer && (
              <>
                <Markdown
                  className="text-sm min-h-3.5 text-gray-600 markdown-body w-full py-3 select-text"
                  remarkPlugins={[remarkGfm]}
                >
                  {answer}
                </Markdown>
                {!autoScroll && (
                  <button
                    onClick={() => {
                      scrollRef.current?.scrollTo({
                        top: scrollRef.current?.scrollHeight + 5,
                        behavior: "smooth",
                      });
                    }}
                    className="absolute flex justify-center items-center w-8 h-8 z-[1] rounded-full right-1/2 bottom-6 shadow-sharp-thin text-white text-[20px] bg-black/70 backdrop-blur-[2px]"
                  >
                    {isLoading ? (
                      <TypingEffect
                        css={[
                          {
                            paddingLeft: 3,
                            paddingRight: 0,
                            paddingBottom: 6,
                            "& > div": tw`bg-white h-1 w-1 mr-[3px]`,
                          },
                        ]}
                      />
                    ) : (
                      <ArrowDown size={14} />
                    )}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AskAiForm;
