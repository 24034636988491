import { Download, Pencil } from "lucide-react";
import { useEffect, useRef } from "react";

interface HeaderSectionProps {
  fileName: string;
  isEditingName: boolean;
  setIsEditingName: (value: boolean) => void;
  handleNameBlur: () => void;
  handleNameKeyDown: (e: React.KeyboardEvent) => void;
  handleDownload: () => void;
  onFileNameChange: (value: string) => void;
}

export const HeaderSection = ({
  fileName,
  isEditingName,
  setIsEditingName,
  handleNameBlur,
  handleNameKeyDown,
  handleDownload,
  onFileNameChange,
}: HeaderSectionProps) => {
  const nameInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditingName && nameInputRef.current) {
      nameInputRef.current.focus();
      nameInputRef.current.select();
    }
  }, [isEditingName]);

  return (
    <div className="flex items-center flex-1 min-w-0">
      <div className="flex-1 flex items-center min-w-0 pr-4">
        {isEditingName ? (
          <div className="flex-1 relative">
            <input
              ref={nameInputRef}
              type="text"
              value={fileName}
              onChange={(e) => onFileNameChange(e.target.value)}
              onBlur={handleNameBlur}
              onKeyDown={handleNameKeyDown}
              className="w-full text-lg font-semibold bg-white px-0 focus:outline-none"
              autoComplete="off"
            />
            <div className="absolute bottom-0 left-0 right-0 h-[2px] bg-blue-500" />
          </div>
        ) : (
          <div className="group flex items-center min-w-0 cursor-pointer" onClick={() => setIsEditingName(true)}>
            <div className="truncate text-lg font-semibold group-hover:text-blue-600">{fileName}</div>
            <div className="flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 transition-opacity">
              <Pencil size={18} className="text-gray-500" />
            </div>
          </div>
        )}
      </div>
      <button onClick={handleDownload} className="p-1 duration-150 rounded-md hover:bg-zinc-100">
        <Download size={20} className="text-[#1987EA]" />
      </button>
    </div>
  );
};
