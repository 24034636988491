/** @jsxImportSource @emotion/react */

import { StepValue } from "../../types";
import { useAppSelector } from "store/storeTypes";
import { useSelection } from "../SelectionContext";
import { Minus, Plus, X } from "lucide-react";
import MergeRequirementsButton from "./MergeRequirementsButton";
import { useExtractionBulkActions } from "./hooks";
import Tooltip from "components/atoms/tooltip/Tooltip";
import VultronAiDropdown from "./VultronAiDropdown";
import { useFlags } from "hook/useFlags";
import { useState } from "react";

const BulkFloatingPillActions = () => {
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
  const isRequirementsStep = extraction?.step === StepValue.Requirements;
  const { selectedBlocks = [], clearSelection } = useSelection();
  const flags = useFlags();
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const {
    allBlocksAreSelected,
    allBlocksAreUnselected,
    allBlocksAreUnassigned,
    addItemToMatrix,
    removeItemFromMatrix,
    unassignItemFromOutline,
  } = useExtractionBulkActions();

  return (
    <div
      ref={setContainerRef}
      className="relative w-fit whitespace-nowrap select-none shadow-lg shadow-black/40 z-[10] rounded-md p-2 bg-black/85 h-[46px] backdrop-blur-lg bottom-4 left-1/2 -translate-x-1/2 flex flex-row items-center gap-2 animate-fadeIn duration-100"
    >
      <div className="shrink-0 flex h-7 items-center">
        <div className="text-gray-200 h-[inherit] flex items-center px-2 text-xs border border-r-0 border-dashed border-gray-500 rounded-l">
          {selectedBlocks.length} chosen
        </div>
        <button
          onClick={clearSelection}
          className="h-[inherit] text-gray-200 text-base px-1.5 border border-dashed border-gray-500 rounded-r duration-100 hover:text-white hover:border-gray-100"
        >
          <X size={16} />
        </button>
      </div>
      {isRequirementsStep && selectedBlocks.length > 1 && (
        <>
          <div className="w-px bg-gray-500 h-[24px]" />
          <MergeRequirementsButton />
        </>
      )}
      <div className="w-px bg-gray-500 h-[24px]" />
      <div className="shrink-0 flex h-7 items-center">
        <div className="text-xs flex items-center gap-2 max-w-80 font-medium border px-2.5 py-1.5 border-gray-400 rounded-l duration-100 text-gray-200">
          Compliance Matrix
        </div>
        <Tooltip
          content={
            allBlocksAreSelected
              ? "All selected requirements have already been added to the compliance matrix."
              : "Add to compliance matrix"
          }
          contentProps={{ style: { maxWidth: "600px" } }}
        >
          <button
            onClick={addItemToMatrix}
            className="text-xs flex items-center gap-2 max-w-80 font-medium border px-1.5 py-1.5 border-gray-400 duration-100 text-gray-200 hover:text-white hover:border-gray-100 hover:bg-black disabled:text-gray-500 disabled:cursor-not-allowed disabled:bg-transparent disabled:border-gray-500"
            disabled={allBlocksAreSelected}
          >
            <Plus size={16} />
          </button>
        </Tooltip>
        <Tooltip
          content={
            allBlocksAreUnselected
              ? "None of the selected requirements are included in the compliance matrix."
              : "Remove from compliance matrix"
          }
          contentProps={{ style: { maxWidth: "600px" } }}
        >
          <button
            onClick={removeItemFromMatrix}
            className="text-xs flex items-center gap-2 max-w-80 font-medium border px-1.5 py-1.5 border-gray-400 rounded-r duration-100 text-gray-200 hover:text-white hover:border-gray-100 hover:bg-black disabled:text-gray-500 disabled:cursor-not-allowed disabled:bg-transparent disabled:border-gray-500"
            disabled={allBlocksAreUnselected}
          >
            <Minus size={16} />
          </button>
        </Tooltip>
      </div>
      <div className="w-px bg-gray-500 h-[24px]" />
      <div className="shrink-0 flex h-7 items-center">
        <div className="text-xs flex items-center gap-2 max-w-80 font-medium border px-2.5 py-1.5 border-gray-400 rounded-l duration-100 text-gray-200">
          Proposal Outline
        </div>
        <Tooltip
          content={
            allBlocksAreUnassigned
              ? "None of the selected requirements are included in the proposal outline."
              : "Unassign from proposal outline"
          }
          contentProps={{ style: { maxWidth: "600px" } }}
        >
          <button
            onClick={unassignItemFromOutline}
            className="text-xs flex items-center gap-2 max-w-80 font-medium border px-1.5 py-1.5 border-gray-400 rounded-r duration-100 text-gray-200 hover:text-white hover:border-gray-100 hover:bg-black disabled:text-gray-500 disabled:cursor-not-allowed disabled:bg-transparent disabled:border-gray-500"
            disabled={allBlocksAreUnassigned}
          >
            <Minus size={16} />
          </button>
        </Tooltip>
      </div>
      {flags.liveTemplateGeneration && (
        <>
          <div className="w-px bg-gray-500 h-[24px]" />
          <VultronAiDropdown portalProps={{ container: containerRef }} />
        </>
      )}
    </div>
  );
};

export default BulkFloatingPillActions;
